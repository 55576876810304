import React, { useState } from "react";
import {
  Container,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { string, number } from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

const questions = [
  { question: "What is your name?", type: string, required: true },
  { question: "What is your age?", type: number, required: true },
  { question: "What is your email?", type: string, required: true },
  { question: "What is your occupation?", type: string, required: true },
  { question: "What is your address?" },
  { question: "What is your phone number?" },
  { question: "What is your favorite hobby?" },
  { question: "What is your favorite food?" },
  { question: "What is your favorite color?" },
  { question: "What is your favorite movie?" },
];

const Questionnaire = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState(Array(questions.length).fill(""));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const steps = Math.ceil(questions.length / 3);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Box sx={{ width: "100%", marginTop: 5 }}>
          <Stepper activeStep={Math.floor(activeStep / 3)}>
            {Array.from({ length: steps }).map((_, index) => (
              <Step key={index}>
                <StepLabel>Step {index + 1}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ marginTop: 3 }}>
          {questions.slice(activeStep, activeStep + 3).map((question, index) => (
            <TextField
              key={index}
              label={question.question}
              variant="outlined"
              fullWidth
              margin="normal"
              value={answers[activeStep + index]}
              onChange={(e) => handleAnswerChange(activeStep + index, e.target.value)}
            />
          ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 3 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Typography variant="body1">
            {activeStep + 1} - {Math.min(activeStep + 3, questions.length)} of {questions.length}
            Questions
          </Typography>
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={activeStep >= questions.length - 3}
          >
            Next
          </Button>
        </Box>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Questionnaire;
