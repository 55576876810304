/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
import Variables from "../../../config/variables";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

function translateStatus(status) {
  switch (status) {
    case 0:
      return "new";
    case 1:
      return "inprogress";
    case 2:
      return "complete";
    case 3:
      return "cancelled";
    default:
      return "unknown";
  }
}

export default function data() {
  const [rows, setRows] = useState([]);
  const [assessments, setAssessments] = useState({});
  const [assessmentRows, setAssessmentRows] = useState([]);
  const [loading, setLoading] = useState(true);
  let company_id = "c7deddc43c6e4d52bdc3151298e6f31f";

  useEffect(() => {
    fetch(`${Variables.apiEndpoint}/api/company/${company_id}/assessments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": JSON.parse(localStorage.getItem("token")).accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAssessments(data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let assessmentRowsLocal = [];
    if (Object.keys(assessments).length !== 0) {
      let current = assessments.assessments.current;
      for (let i = 0; i < current.length; i++) {
        let assessment = current[i];
        let assessment_id = assessment.assessment_id;
        let assessment_name = "Test";
        let status = translateStatus(assessment.assessment_data[0].status);
        let completion = Number(assessment.assessment_data[0].percentComplete);
        let action = (
          <MDTypography component="a" href={`./assessment/${assessment_id}`} color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        );
        let row = {
          assessment: assessment_name + " (" + assessment_id + ")",
          status: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {status}
            </MDTypography>
          ),
          completion: <Progress color="info" value={completion} />,
          action: action,
        };
        assessmentRowsLocal.push(row);
      }
    }
    setAssessmentRows(assessmentRowsLocal);
  }, [assessments]);

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  if (loading) {
    return {
      columns: [
        { Header: "assessment", accessor: "assessment", width: "10%", align: "left" },
        { Header: "status", accessor: "status", align: "center" },
        { Header: "completion", accessor: "completion", align: "center" },
        { Header: "action", accessor: "action", align: "center" },
      ],
      rows: [],
    };
  }

  return {
    columns: [
      { Header: "assessment", accessor: "assessment", width: "10%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "completion", accessor: "completion", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: assessmentRows,

    /*rows: [
      {
        assessment: "SAQ A",
        status: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            incomplete
          </MDTypography>
        ),
        completion: <Progress color="info" value={0} />,
        action: (
          <MDTypography component="a" href="./assessment/saq-a" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
      {
        assessment: "SAQ B",
        status: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            incomplete
          </MDTypography>
        ),
        completion: <Progress color="success" value={0} />,
        action: (
          <MDTypography component="a" href="./assessment/saq-b" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
      {
        assessment: "SAQ C",
        status: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            incomplete
          </MDTypography>
        ),
        completion: <Progress color="error" value={0} />,
        action: (
          <MDTypography component="a" href="./assessment/saq-c" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
      {
        assessment: "SAQ D",
        status: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            incomplete
          </MDTypography>
        ),
        completion: <Progress color="info" value={0} />,
        action: (
          <MDTypography component="a" href="./assessment/saq-d" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
    ],*/
  };
}
