// React components
import React from "react";
import { Link } from "react-router-dom";

// @mui material components
import { Box, Button, Typography, Container } from "@mui/material";

function IntroductionPage() {
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Welcome to Our Website
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          We provide the best services for you.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/dashboard">
          Go to Dashboard
        </Button>
      </Box>
    </Container>
  );
}

export default IntroductionPage;
