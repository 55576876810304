/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";
import { jwtDecode } from "jwt-decode";
import Variables from "config/variables";
import PropTypes from "prop-types";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import { CircularProgress, Box } from "@material-ui/core";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
  setUserAuthenticated,
  setUserContext,
  setCompanyContext,
} from "context";

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

// Routes
import Dashboard from "layouts/dashboard";
import Assessments from "layouts/assessments";
import Assessment from "layouts/assessment";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SignUp2 from "layouts/authentication/sign-up2";
import Home from "layouts/home";

function ProtectedRoute({ element, authenticated }) {
  const location = useLocation();
  const [controller] = useMaterialUIController();
  const { sidenavColor, transparentSidenav, whiteSidenav, darkMode, miniSidenav } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  if (!authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
        brandName="TeaVirus Demo"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Configurator />
      {element}
    </>
  );
}

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    userAuthenticated,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      setRedirectToLogin(true);
      setLoading(false);
    }
    try {
      const tokens = localStorage.getItem("token");
      const authToken = JSON.parse(tokens).accessToken;
      const refreshToken = JSON.parse(tokens).refreshToken;

      if (authToken) {
        console.log("Token Found");
        const decodedToken = jwtDecode(authToken);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp > currentTime) {
          // Token is not expired, make the API call
          fetch(`${Variables.apiEndpoint}/api/authenticate`, {
            headers: {
              "x-access-token": authToken,
            },
          })
            .then((response) => {
              console.log(response);
              if (response.ok) {
                console.log("Token Valid");
                // The token is valid, set userAuthenticated to true
                setUserAuthenticated(dispatch, true);
                setLoading(false);
              } else {
                // The token is not valid, set userAuthenticated to false
                console.log("Token Invalid");
                setUserAuthenticated(dispatch, false);
                setLoading(false);
                setRedirectToLogin(true);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              // In case of an error, set userAuthenticated to false
              setUserAuthenticated(dispatch, false);
              setRedirectToLogin(true);
              setLoading(false);
            });
        } else {
          console.log("Token Expired");
          const decodedRefreshToken = jwtDecode(refreshToken);
          const currentTime = Date.now() / 1000;

          if (decodedRefreshToken.exp > currentTime) {
            fetch(`${Variables.apiEndpoint}/api/renew-token`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ refreshToken: refreshToken }),
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.accessToken) {
                  // New access token received, save it to local storage
                  localStorage.setItem(
                    "token",
                    JSON.stringify({ accessToken: data.accessToken, refreshToken: refreshToken })
                  );
                  // Set userAuthenticated to true
                  setUserAuthenticated(dispatch, true);
                  setLoading(false);
                } else {
                  // Delete Localstorage
                  localStorage.removeItem("token");
                  // No access token received, set userAuthenticated to false
                  setUserAuthenticated(dispatch, false);
                  setLoading(false);
                  setRedirectToLogin(true);
                }
              })
              .catch((error) => {
                console.error("Error:", error);
                // In case of an error, set userAuthenticated to false
                setUserAuthenticated(dispatch, false);
                setRedirectToLogin(true);
                setLoading(false);
              });
          } else {
            console.log("Refresh Token Expired");
            // Refresh token is expired, set userAuthenticated to false
            setUserAuthenticated(dispatch, false);
            setRedirectToLogin(true);
            setLoading(false);
          }
        }
      } else {
        console.log("No Token Found");
        // No token, set userAuthenticated to false
        setUserAuthenticated(dispatch, false);
        setLoading(false);
      }
    } catch (err) {
      //console.error(err);
      setUserAuthenticated(dispatch, false);
      setLoading(false);
    }
  }, []);

  // If authenticated, grab user permissions from api
  useEffect(() => {
    if (userAuthenticated) {
      fetch(`${Variables.apiEndpoint}/api/me`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": JSON.parse(localStorage.getItem("token")).accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setUserContext(dispatch, data);
        });
    }
  }, [userAuthenticated]);

  // If authenticated, grab user permissions from api
  useEffect(() => {
    if (userAuthenticated) {
      fetch(`${Variables.apiEndpoint}/api/companies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": JSON.parse(localStorage.getItem("token")).accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setCompanyContext(dispatch, data);
        });
    }
  }, [userAuthenticated]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<Dashboard />} authenticated={userAuthenticated} />}
        />
        <Route
          path="/assessments"
          element={<ProtectedRoute element={<Assessments />} authenticated={userAuthenticated} />}
        />
        <Route
          path="/assessment/:id"
          element={<ProtectedRoute element={<Assessment />} authenticated={userAuthenticated} />}
        />
        <Route
          path="/billing"
          element={<ProtectedRoute element={<Billing />} authenticated={userAuthenticated} />}
        />
        <Route
          path="/notifications"
          element={<ProtectedRoute element={<Notifications />} authenticated={userAuthenticated} />}
        />
        <Route
          path="/account"
          element={<ProtectedRoute element={<Profile />} authenticated={userAuthenticated} />}
        />
        <Route path="/login" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/verify-email" element={<SignUp2 />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </ThemeProvider>
  );
}
